<template>
    <div>
        <div class="fadeIn" v-show="section==1">
            <ValidationObserver ref="form1">
                <CommonModal ref="kvkk1Modal" size="xl">
                    <template v-slot:CommonModalTitle>
                        <div>{{ $t('statement_3').toUpper() }}</div>
                    </template>
                    <template v-slot:CommonModalContent>
                        <div>
                            <div class="row">
                                <div v-html="$t('kvkk_content')" class="col-12">
                                </div>
                            </div>
                            <b-button class="btn--primary" @click="continueProcess">{{
                                    $t('read_and_accept')
                                }}
                            </b-button>
                        </div>
                    </template>
                </CommonModal>
                <div class="mx-auto">
                    <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                        <b-form-group :label="$t('email')">
                            <b-form-input type="text"
                                          v-model="form1.email"
                                          :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('password')" class="input-icon position-relative">
                            <b-form-input
                                :type="loginPasswordShowStatus ? 'text':'password'"
                                v-model="form1.password"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                            <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                               @click="loginPasswordShowStatus=true"
                               v-if="!loginPasswordShowStatus"></i>
                            <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                               @click="loginPasswordShowStatus=false"
                               v-if="loginPasswordShowStatus"></i>
                        </b-form-group>
                    </ValidationProvider>
                    <b-form-group>
                        <b-button
                            @click="form1Submit"
                            type="submit"
                            variant="primary"
                            class="btn-lg btn-block d-flex justify-content-between align-items-center"
                        >
                            {{ $t('login').toUpper() }}
                            <i class="ri-arrow-right-line"></i>
                        </b-button>
                    </b-form-group>
                    <a class="small text-center d-block text-muted cursor-pointer"
                       @click="changeActivePage('forgot-password')">
                        {{ $t('forgot_my_password').toUpper() }}
                    </a>
                    <a class="small text-center d-block text-muted cursor-pointer mt-2"
                       @click="openVideoModal()">
                        {{ $t('How to Reset My BAU Password').toUpper() }}
                    </a>
                </div>
            </ValidationObserver>
        </div>
        <div class="fadeIn" v-show="section==2">
            <ValidationObserver ref="form2">
                <input type="text" v-model="form2.token" class="form-control d-none">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="col-form-label">{{ $t('verification_code') }}</label>
                    <div class="text-muted">
                        <i class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn
                    </div>
                </div>
                <ValidationProvider name="smsCode" rules="required|length:6" v-slot="{valid, errors}">
                    <b-form-group>
                        <b-form-input type="text"
                                      v-model="form2.sms_code"
                                      class="d-none"
                                      :state="errors[0] ? false : (valid ? true : null)">
                        </b-form-input>
                        <div>
                            <otp-input
                                class="sms-input"
                                ref="otpInput"
                                inputClasses="form-control text-center font-size-1"
                                :numInputs="6"
                                separator=""
                                :shouldAutoFocus="false"
                                @on-complete="otpHandleOnComplete"
                                @on-change="otpHandleOnChange"
                            >
                                <button @click="handleClearInput()">Clear Input</button>
                            </otp-input>
                        </div>
                        <b-form-invalid-feedback v-if="errors[0]" :v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <div class="row">
                    <div class="col-6">
                        <b-form-group>
                            <b-button type="submit"
                                      variant="primary"
                                      class="btn-lg btn-block d-flex justify-content-between align-items-center"
                                      @click="form2Submit"
                                      :disabled="timerCount==0">
                                {{ $t('verify') }}
                            </b-button>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group>
                            <b-button type="submit"
                                      variant="outline-secondary"
                                      class="btn-lg btn-block d-flex justify-content-between align-items-center mt-0"
                                      @click="form1Submit"
                                      :disabled="timerCount!=0">
                                {{ $t('send_again').toUpper() }}
                            </b-button>
                        </b-form-group>
                    </div>
                </div>
                <a class="small text-uppercase text-center d-block text-muted cursor-pointer" @click="backToSection1()">
                    <i class="ri-arrow-left-line mr-2 h6 font-weight-normal top-plus-2"></i>
                    {{ $t('back').toUpper() }}
                </a>
            </ValidationObserver>
        </div>


        <CommonModal size="xl" ref="VideoModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                <div>{{$t('how_to_reset_my_bau_password')}}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ForgetPassportVideo v-if="formProgress==='how_to_reset_my_password'"/>
                </div>
            </template>
        </CommonModal>

    </div>
</template>
<script>
import Base from '@/plugins/Base'
import AuthService from '@/services/AuthService'
import OtpInput from '@bachdgvn/vue-otp-input';
import isset from 'isset-php';

import CommonModal from "@/components/elements/CommonModal.vue";

import {ValidationObserver, ValidationProvider} from "vee-validate";

import StudentProgramService from "@/services/StudentProgramService";
import Ranking from "@/modules/placementExamPartClassrooms/pages/Ranking.vue";
import ForgetPassportVideo from "@/modules/auth/pages/ForgetPassportVideo.vue";

export default {
    components: {
        ForgetPassportVideo,
        Ranking,
        ValidationProvider,
        ValidationObserver,
        OtpInput,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t('login'),

        }
    },
    props:{
        isCaptchaEnabled:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            section: 1,
            formProgress:null,

            loginPasswordShowStatus: false,
            form1: {
                email: null,
                password: null
            },
            form2: {
                sms_token: null,
                sms_code: null
            },

            // Timer
            timerEnabled: false,
            timerCount: 0,

            kvkk_status: false,
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    this.$options.interval = setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false
                    this.form2 = {
                        sms_token: null,
                        sms_code: null
                    };
                    if (this.section == 2) {
                        this.$refs.otpInput.clearInput();
                    }
                }
            },
            immediate: true
        }
    },
    created() {
        this.section = 1;
    },
    methods: {
        changeActivePage(page) {
            this.$store.commit('auth/setActivePage', page);
        },
        formClear(){
          this.formProgress=null
        },
        onCaptchaVerified: function (recaptchaToken) {
            this.form1.g_recaptcha_token=recaptchaToken;
            return AuthService.login(this.form1)
                .then(response => {
                    const data = response.data.data;
                    if (isset(() => data.sms_token)) {
                        this.form2.sms_token = data.sms_token;
                        this.timerCount = data.expires_in;
                        this.timerEnabled = true;

                        this.section = 2;
                    } else {
                        this.login(data)
                    }
                })
                .catch(error => {
                    if (error.status == 422) {
                        if (error.data.errors.email) {
                            this.$refs.form1.errors.email.push(error.data.errors.email[0]);
                        }
                        if (error.data.errors.password) {
                            this.$refs.form1.errors.password.push(error.data.errors.password[0]);
                        }
                    } else if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        async form1Submit() {
            const isValid = await this.$refs.form1.validate();
            if (isValid) {
                if(this.isCaptchaEnabled){
                    this.$emit('captcha', 'login');
                }
                else {
                    this.onCaptchaVerified('x');
                }
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        },
        async form2Submit() {
            const isValid = await this.$refs.form2.validate();
            if (isValid && this.timerCount > 0) {
                AuthService.loginTwoFactor(this.form2)
                    .then(response => {
                        const data = response.data.data;
                        this.login(data);
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.sms_code) {
                                this.$refs.form2.errors.smsCode.push(error.data.errors.sms_code[0]);
                            }
                        } else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_must_fill_in_the_fields_indicated_in_red'));
            }
        },
        login(data) {
            if (data.access_token) {
                data.access_token = data.access_token.replace(/^"+/, '').replace(/"+$/, '');
                Base.LocalStorage.set('access_token', 'Bearer ' + data.access_token);
                this.$store.commit('auth/setToken', 'Bearer ' + data.access_token);
            }
            if (data.expires_in) {
                let expireIn = parseInt(data.expires_in) * 60000;
                Base.LocalStorage.set('access_token_expires_in', new Date().getTime() + expireIn);
                this.$store.dispatch('auth/setTimeoutTimer', expireIn)
            }


            this.$store.dispatch('auth/getUser').then(() => {
                if (this.$route.query.redirect && this.$route.query.redirect != '/') {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/dashboard');
                }
            });
        },
        otpHandleOnComplete(value) {
            this.form2.sms_code = value;
        },
        otpHandleOnChange(value) {
            this.form2.sms_code = value
        },
        backToSection1() {
            this.section = 1;
            this.timerCount = 0;
            clearInterval(this.$options.interval);
        },
        continueProcess() {
            StudentProgramService.approveKvkk()
                .then(() => {
                    this.$toast.success(this.$t('proccess_success'));
                    this.$router.push('/dashboard');
                })
                .catch(err => this.showErrors(err))
        },
        openVideoModal(){
            this.formProgress='how_to_reset_my_password'
            this.$refs.VideoModal.$refs.commonModal.show()


        }
    }
}
</script>
