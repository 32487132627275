<template>
    <div>
        <div class="d-flex flex-column vh-100">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AuthLayout',
        created() {
            this.$store.dispatch('auth/initAuthPage');
        }
    }
</script>
