<template>
    <div class="language mx-auto text-center mb-4">
        <a class="small text-center mx-2 cursor-pointer text-muted"
           @click="changeLocale('tr');"
           v-if="locale!='tr'">
            TÜRKÇE
        </a>
        <a class="small text-center mx-2 cursor-pointer text-body"
           @click="changeLocale('en');"
           v-if="locale!='en'">
            ENGLISH
        </a>
    </div>
</template>
<script>
    import {localeChanged} from 'vee-validate';

    export default {
        computed: {
            locale() {
                return this.$store.getters['getLocale']
            }
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale;
                this.$store.commit('setLocale', locale)
                localeChanged();
            }
        }
    };
</script>
